import * as pkg from '../package.json';

const {hostname} = window.location;
const environments = {
  local: {
    DATA_BASE_URL: `http://${hostname}:8080/`,
    IS_ANALYTICS_ENABLED: true,
    ANALYTICS_BASE_URL: `http://${hostname}:8083/`,
    WSS_HOST: 'wss://hrvn29vmvg.execute-api.us-east-1.amazonaws.com/Prod',
  },
  hyperlive: {
    development: {
      DATA_BASE_URL: 'https://api.development.nikehyperlive.com/',
      IS_ANALYTICS_ENABLED: true,
      ANALYTICS_BASE_URL: `https://api.analytics.development.nikesportpulse.com/`,
      WSS_HOST: 'wss://1cwj8i9uai.execute-api.us-east-1.amazonaws.com/Prod',
    },
    staging: {
      DATA_BASE_URL: 'https://api.staging.nikehyperlive.com/',
      IS_ANALYTICS_ENABLED: true,
      ANALYTICS_BASE_URL: `https://api.analytics.staging.nikesportpulse.com/`,
      WSS_HOST: 'wss://1cwj8i9uai.execute-api.us-east-1.amazonaws.com/Prod',
    },
    production: {
      DATA_BASE_URL: 'https://api.nikehyperlive.com/',
	  IS_ANALYTICS_ENABLED: true,
	  ANALYTICS_BASE_URL: `https://api.analytics.nikesportpulse.com/`,
      WSS_HOST: 'wss://qyak8lou6e.execute-api.us-east-1.amazonaws.com/Prod',
    },
  },
  sportpulse: {
    development: {
      DATA_BASE_URL: 'https://api.development.nikesportpulse.com/',
      IS_ANALYTICS_ENABLED: true,
      ANALYTICS_BASE_URL: `https://api.analytics.development.nikesportpulse.com/`,
      WSS_HOST: 'wss://1cwj8i9uai.execute-api.us-east-1.amazonaws.com/Prod',
    },
    staging: {
      DATA_BASE_URL: 'https://api.staging.nikesportpulse.com/',
		IS_ANALYTICS_ENABLED: true,
		ANALYTICS_BASE_URL: `https://api.analytics.staging.nikesportpulse.com/`,
      WSS_HOST: 'wss://1cwj8i9uai.execute-api.us-east-1.amazonaws.com/Prod',
    },
    production: {
      DATA_BASE_URL: 'https://api.nikesportpulse.com/',
      IS_ANALYTICS_ENABLED: true,
      ANALYTICS_BASE_URL: `https://api.analytics.nikesportpulse.com/`,
      WSS_HOST: 'wss://qyak8lou6e.execute-api.us-east-1.amazonaws.com/Prod',
    },
  },
  china: {
    staging: {
      DATA_BASE_URL: 'https://api.sportpulse.test.nikecloud.com.cn/',
      IS_ANALYTICS_ENABLED: true,
      ANALYTICS_BASE_URL: 'https://api.analytics.sportpulse.test.nikecloud.com.cn/',
      WSS_HOST: '',
      CLOUDINARY_DOMAIN: 'static.nike.com.cn',
      CLOUDINARY_CLOUD_NAME: 'sportpulse'
    },
    preProduction: {
      DATA_BASE_URL: 'https://api.sportpulse.prod.nikecloud.com.cn/',
      IS_ANALYTICS_ENABLED: true,
      ANALYTICS_BASE_URL: 'https://api.analytics.sportpulse.test.nikecloud.com.cn/',
      WSS_HOST: '',
      CLOUDINARY_DOMAIN: 'static.nike.com.cn',
      CLOUDINARY_CLOUD_NAME: 'sportpulse'
    },
    production: {
      DATA_BASE_URL: 'https://api.sportpulse.nike.com.cn/',
      IS_ANALYTICS_ENABLED: true,
      ANALYTICS_BASE_URL: 'https://api.analytics.sportpulse.nike.com.cn/',
      WSS_HOST: '',
      CLOUDINARY_DOMAIN: 'static.nike.com.cn',
      CLOUDINARY_CLOUD_NAME: 'sportpulse'
    }
  }
};

let environment = environments.local;

(() => {
  // eslint-disable-next-line default-case
  switch (hostname) {
    // Hyperlive.com
    case 'www.development.nikehyperlive.com':
    case 'displays.development.nikehyperlive.com':
      environment = environments.hyperlive.development;
      break;
    case 'www.staging.nikehyperlive.com':
    case 'staging.nikehyperlive.com':
    case 'displays.staging.nikehyperlive.com':
      environment = environments.hyperlive.staging;
      break;
    case 'www.nikehyperlive.com':
    case 'displays.nikehyperlive.com':
      environment = environments.hyperlive.production;
      break;

    // SportPulse.com
    case 'www.development.nikesportpulse.com':
    case 'displays.development.nikesportpulse.com':
      environment = environments.sportpulse.development;
      break;
    case 'www.staging.nikesportpulse.com':
    case 'displays.staging.nikesportpulse.com':
      environment = environments.sportpulse.staging;
      break;
    case 'www.nikesportpulse.com':
    case 'nikesportpulse.com':
    case 'displays.nikesportpulse.com':
      environment = environments.sportpulse.production;
      break;

    // China Environment
    case 'sportpulse.test.nikecloud.com.cn':
    case 'displays.sportpulse.test.nikecloud.com.cn':
      environment = environments.china.staging;
      break;
    case 'sportpulse.prod.nikecloud.com.cn':
    case 'displays.sportpulse.prod.nikecloud.com.cn':
      environment = environments.china.preProduction;
      break;
    case 'sportpulse.nike.com.cn':
    case 'displays.sportpulse.nike.com.cn':
      environment = environments.china.production;
      break;
  }

  if (
    hostname.indexOf('qa-cn--hyperlive.netlify.app') >= 0
  ) {
    environment = environments.china.production;
  } else if (
    hostname.indexOf('legacy--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('serena--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('qa-prd--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('typescript--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('westlondon--hyperlive.netlify.app') >= 0
  ) {
    environment = environments.sportpulse.production;
  } else if (hostname.indexOf('netlify.app') >= 0) {
    environment = environments.sportpulse.staging;
  }

  if (hostname.indexOf('localhost') >= 0) {
    // environment = environments.local;
    // environment = environments.sportpulse.staging;
    environment = environments.sportpulse.production;
    // environment = environments.china.production;
  }

  // Overrides (for development purposes)
  // environment = environments.local;
  // environment = environments.hyperlive.development;
  // environment = environments.hyperlive.staging;
  // environment = environments.hyperlive.production;
})();

const enableLocalStorageCache = () => {
	const {
		host,
		pathname
	} = window.location;

	return host.includes('displays')
		|| host.includes('1234')
		|| pathname.split('/')[1] === 'touchpoint';
};

export const {
  DATA_BASE_URL,
  WSS_HOST,
  CLOUDINARY_DOMAIN = 'res.cloudinary.com',
  CLOUDINARY_CLOUD_NAME = 'jbboerner',
  ENABLED_DISPLAYS_LOCAL_STORAGE_CACHE = enableLocalStorageCache(),
  IS_ANALYTICS_ENABLED,
  ANALYTICS_BASE_URL,
} = environment;
export const VERSION = pkg.version;
